window.IniciarFirmaDedo = function () {
    /*
		El siguiente codigo en JS Contiene mucho codigo
		de las siguietes 3 fuentes:
		https://stipaltamar.github.io/dibujoCanvas/
		https://developer.mozilla.org/samples/domref/touchevents.html - https://developer.mozilla.org/es/docs/DOM/Touch_events
		http://bencentra.com/canvas/signature/signature.html - https://bencentra.com/code/2014/12/05/html5-canvas-touch-events.html
*/
    try {
        (function () { // Comenzamos una funcion auto-ejecutable

            // Obtenenemos un intervalo regular(Tiempo) en la pamtalla
            window.requestAnimFrame = (function (callback) {
                return window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    window.oRequestAnimationFrame ||
                    window.msRequestAnimaitonFrame ||
                    function (callback) {
                        window.setTimeout(callback, 1000 / 60);
                        // Retrasa la ejecucion de la funcion para mejorar la experiencia
                    };
            })();

            // Traemos el canvas mediante el id del elemento html
            var canvas = document.getElementById("draw-canvas");
            var ctx = canvas.getContext("2d");
            ctx.canvas.width = canvas.offsetWidth
            ctx.canvas.height = 180;

            // Mandamos llamar a los Elemetos interactivos de la Interfaz HTML
            var drawText = document.getElementById("draw-dataUrl");
            var uploadImage = document.getElementById("upload-image");
            var drawImage = document.getElementById("draw-image");
            var drawImagePreview = document.getElementById("image-preview-firma");
            var clearBtn = document.getElementById("draw-clearBtn");
            var submitBtn = document.getElementById("draw-submitBtn");
            uploadImage.addEventListener('change', (e) => {
                // Get a reference to the file
                const file = e.target.files[0];

                // Encode the file using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    CargarImagen(reader.result)
                    var img = new Image();

                    img.onload = function () {
                        ctx.drawImage(img, 0, 0, img.width, img.height,     // source rectangle
                            0, 0, canvas.width, canvas.height);
                    };
                    img.src = reader.result
                    uploadImage.value = ""
                };
                reader.readAsDataURL(file);
            });
            clearBtn.addEventListener("click", function (e) {
                // Definimos que pasa cuando el boton draw-clearBtn es pulsado

                $("#ir-paso-preview-final").addClass("disabled");
                $(".bt-firmar").addClass("disabled");
                drawText.innerHTML = "Base64"
                clearCanvas();
                drawImage.setAttribute("src", "");
            }, false);
            // Definimos que pasa cuando el boton draw-submitBtn es pulsado
            submitBtn.addEventListener("click", function (e) {
                var dataUrl = canvas.toDataURL();
                CargarImagen(dataUrl)

            }, false);

            // Activamos MouseEvent para nuestra pagina
            var drawing = false;
            var mousePos = {x: 0, y: 0};
            var lastPos = mousePos;
            canvas.addEventListener("mousedown", function (e) {
                /*
                  Mas alla de solo llamar a una funcion, usamos function (e){...}
                  para mas versatilidad cuando ocurre un evento
                */
                var tint = document.getElementById("color");
                var punta = document.getElementById("puntero");
                console.log(e);
                drawing = true;
                lastPos = getMousePos(canvas, e);
            }, false);
            canvas.addEventListener("mouseup", function (e) {
                drawing = false;
                submitBtn.click()
            }, false);
            canvas.addEventListener("mousemove", function (e) {
                mousePos = getMousePos(canvas, e);

            }, false);

            // Activamos touchEvent para nuestra pagina
            canvas.addEventListener("touchstart", function (e) {
                mousePos = getTouchPos(canvas, e);
                console.log(mousePos);
                e.preventDefault(); // Prevent scrolling when touching the canvas
                var touch = e.touches[0];
                var mouseEvent = new MouseEvent("mousedown", {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                canvas.dispatchEvent(mouseEvent);
            }, false);
            canvas.addEventListener("touchend", function (e) {
                e.preventDefault(); // Prevent scrolling when touching the canvas
                var mouseEvent = new MouseEvent("mouseup", {});
                canvas.dispatchEvent(mouseEvent);
            }, false);
            canvas.addEventListener("touchleave", function (e) {
                // Realiza el mismo proceso que touchend en caso de que el dedo se deslice fuera del canvas
                e.preventDefault(); // Prevent scrolling when touching the canvas
                var mouseEvent = new MouseEvent("mouseup", {});
                canvas.dispatchEvent(mouseEvent);
            }, false);
            canvas.addEventListener("touchmove", function (e) {
                e.preventDefault(); // Prevent scrolling when touching the canvas
                var touch = e.touches[0];
                var mouseEvent = new MouseEvent("mousemove", {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                canvas.dispatchEvent(mouseEvent);
            }, false);

            // Get the position of the mouse relative to the canvas
            function getMousePos(canvasDom, mouseEvent) {

                var rect = canvasDom.getBoundingClientRect();
                /*
                  Devuelve el tamaño de un elemento y su posición relativa respecto
                  a la ventana de visualización (viewport).
                */
                return {
                    x: mouseEvent.clientX - rect.left,
                    y: mouseEvent.clientY - rect.top
                };
            }

            // Get the position of a touch relative to the canvas
            function getTouchPos(canvasDom, touchEvent) {
                var rect = canvasDom.getBoundingClientRect();
                console.log(touchEvent);
                /*
                  Devuelve el tamaño de un elemento y su posición relativa respecto
                  a la ventana de visualización (viewport).
                */
                return {
                    x: touchEvent.touches[0].clientX - rect.left,
                    y: touchEvent.touches[0].clientY - rect.top
                };
            }

            // Draw to the canvas
            function renderCanvas() {

                if (drawing) {
                    var tint = document.getElementById("color");
                    var punta = document.getElementById("puntero");

                    ctx.strokeStyle = tint.value;
                    ctx.beginPath();
                    ctx.moveTo(lastPos.x, lastPos.y);
                    ctx.lineTo(mousePos.x, mousePos.y);
                    ctx.lineWidth = punta.value;
                    ctx.stroke();
                    ctx.closePath();
                    lastPos = mousePos;
                }
            }

            function clearCanvas() {
                canvas.width = canvas.width;
            }

            // Allow for animation
            (function drawLoop() {
                requestAnimFrame(drawLoop);
                renderCanvas();
            })();

            function CargarImagen(blob64) {

                drawText.innerHTML = blob64;
                drawImage.setAttribute("src", blob64);
                $("#ir-paso-preview-final").removeClass("disabled");
                $(".bt-firmar").removeClass("disabled");
                html2canvas($("#table-preview"), {
                    onrendered: function (canvas) {
                        var imgageData = canvas.toDataURL("image/png");
                        if (drawImagePreview) {
                            drawImagePreview.setAttribute("src", imgageData);
                            $(".imgFirma").attr("src", imgageData);
                        }

                        $("li.pagina.selected").each(function (i, li) {
                            $(li).find("a")[0].innerText = $(li).attr("id").replace("pag-", "")
                        })

                        $("#pag-1").addClass("selected");
                        if ($("#pag-1 a")[0] != null)
                            $("#pag-1 a")[0].innerText = "[Actual] 1"
                        var anchoVentana = window.innerWidth;
                        try {
                            if (anchoVentana < 600) {
                                CargarPDF(0.5)
                            } else {
                                CargarPDF(1)
                            }
                        } catch (e) {

                        }

                    }
                });
            }

        })();
    } catch (e) {

    }


}

