// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require rails-ujs
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


Rails.start()
Turbolinks.start()
ActiveStorage.start()

import $ from "jquery";

window.jQuery = $;
window.$ = $;

import "foundation-sites"
import "select2"
import "slick-carousel"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import "cocoon-js";

require("jqueryui")
require("packs/foundation.min.js")
require("src/fit_text")
require("src/prism")
require("src/jscolor.js")
require("packs/ruc_validations.js")
require("packs/pdf_canvas.js")
require("packs/firma_con_dedo.js")

Prism.languages.csv = {
    'csv_value': /[^\r\n",]|"(?:[^"]|"")"(?!")/,
    'csv_separator': /,/
};

global.ClipboardJS = require("clipboard/dist/clipboard.min")
global.SumoSelect = require("packs/sumoselect.min.js")

$(document).on('turbolinks:before-render', function () {
    // Fix https://github.com/foundation/foundation-rails/issues/290
    $('html').removeClass('is-reveal-open')
})

$(document).on('turbolinks:load', function () {
    Prism.highlightAll();
    ConsultarRucEvents();
    ValidateRucDni();

    if (document.getElementById("user_rol")) {
        $("#user_rol").change(function () {
            var user_rol = $('#user_rol').val();
            if (user_rol === "GESTOR") {
                $('.permisos_gestor_certificado').show();
            } else {
                $('.permisos_gestor_certificado').hide();
            }
        });
        $("#user_rol").trigger('change');
    }

    var class_sumoselect = document.getElementsByClassName("sumoselect")
    if (class_sumoselect.length > 0) {
        $('.sumoselect').SumoSelect({
            okCancelInMulti: true,
            isClickAwayOk: true,
            selectAll: true,
            placeholder: 'Elegir',
            locale: ['OK', 'Cancelar', 'Elegir todo'],
            captionFormat: '{0} Seleccionado(s)',
            captionFormatAllSelected: '{0} TODO!',
            closeAfterClearAll: true,
            search: true,
            searchText: 'Buscar...',
            noMatch: 'No encontrado "{0}"',
        });
    }

    $("#version_app_sistema_operativo").on("change", function () {
        $.ajax({
            url: "/version_actual",
            type: "POST",
            data: {
                sistema_operativo: $("#version_app_sistema_operativo").val()
            },
            success: function (e) {
                $("#version_app_version").val(e.version)
            }
        })
    });

    if ($("#eventos_programados").length > 0) {
        $.ajax({
            url: "/yanapa/eventos",
            type: "get",
            success: function (e) {
                if (e.success) {
                    $("#eventos_programados").html("");
                    $.each(e.eventos, function (i, event) {
                        var tipo = event.tipo == "MANTENIMIENTO PROGRAMADO" ? "warning" : "alert";
                        var html = "<div class='news " + tipo + "'>"
                        html += "<div class='text-center'>" + event.descripcion + "</div>"
                        html += "</div>";
                        $("#eventos_programados").append(html);
                    });
                }
            },
            error: function (e) {

            }
        })
    }


    $(document).ready(function () {
        jscolor.installByClassName("jscolor");
    });
    ChangeNotaPendiente();
    PrecioCertificado();

    function PrecioCertificado() {
        $(".tipo_de_certificado, .meses_de_vigencia, .tipo_de_almacen").on('change', function () {
            var tipo_de_certificado = $('.tipo_de_certificado option:selected').val();
            var meses_de_vigencia = $('.meses_de_vigencia option:selected').val();
            var tipo_de_almacen = $('.tipo_de_almacen option:selected').val();
            $.post("/certificado/precios", {
                authenticity_token: $('[name="csrf-token"]')[0].content,
                tipo_de_certificado: tipo_de_certificado,
                meses_de_vigencia: meses_de_vigencia,
                para_firma_remota: tipo_de_almacen == "watana-almacen-firma-remota"

            }, function (data) {
                console.log(data)
                if (data.success) {
                    $(".callout.precio").show()
                    document.getElementById("result_precio_cert").innerHTML = data.precio
                } else {
                    $(".callout.precio").hide()
                    $(".precio-certificado").text("-")
                }
            });
        })
        $(".tipo_de_certificado").trigger('change')
    }

    OrganizationPresent();

    function OrganizationPresent() {
        $(".tipo_de_certificado").on('change', function () {
            var tipo_de_certificado = $('.tipo_de_certificado option:selected').val();
            var form_organizacion = $("#form_organizacion")
            if (tipo_de_certificado == "EMPRESA") {
                form_organizacion.show()
                $(".organizacion_documento_tipo").attr("required", "required");
                $(".organizacion_documento_numero").attr("required", "required");
                $(".organizacion_denominacion").attr("required", "required");
                $(".organizacion_area").attr("required", "required");
                // $(".organizacion_direccion").attr("required", "required");
                // $(".organizacion_departamento").attr("required", "required");
                // $(".organizacion_provincia").attr("required", "required");
                // $(".organizacion_distrito").attr("required", "required");
                $(".titular_suscriptor_profesion_cargo").removeAttr("required");
                $(".titular_suscriptor_profesion_cargo").show();
            } else if (tipo_de_certificado == "PERSONA") {
                form_organizacion.hide()
                $(".organizacion_documento_tipo").removeAttr("required");
                $(".organizacion_documento_tipo").val("");
                $(".organizacion_documento_numero").removeAttr("required");
                $(".organizacion_documento_numero").val("");
                $(".organizacion_denominacion").removeAttr("required");
                $(".organizacion_denominacion").val("");
                $(".organizacion_area").removeAttr("required");
                $(".organizacion_area").val("");
                // $(".organizacion_direccion").removeAttr("required");
                // $(".organizacion_direccion").val("");
                // $(".organizacion_departamento").removeAttr("required");
                // $(".organizacion_departamento").val("");
                // $(".organizacion_provincia").removeAttr("required");
                // $(".organizacion_provincia").val("");
                // $(".organizacion_distrito").removeAttr("required");
                // $(".organizacion_distrito").val("");
                $(".titular_suscriptor_profesion_cargo").removeAttr("required");
                $(".titular_suscriptor_profesion_cargo").val("");
                $(".titular_suscriptor_profesion_cargo").hide();
            } else if (tipo_de_certificado == "PROFESIONAL") {
                form_organizacion.hide()
                $(".organizacion_documento_tipo").removeAttr("required");
                $(".organizacion_documento_tipo").val("");
                $(".organizacion_documento_numero").removeAttr("required");
                $(".organizacion_documento_numero").val("");
                $(".organizacion_denominacion").removeAttr("required");
                $(".organizacion_denominacion").val("");
                $(".organizacion_area").removeAttr("required");
                $(".organizacion_area").val("");
                // $(".organizacion_direccion").removeAttr("required");
                // $(".organizacion_direccion").val("");
                // $(".organizacion_departamento").removeAttr("required");
                // $(".organizacion_departamento").val("");
                // $(".organizacion_provincia").removeAttr("required");
                // $(".organizacion_provincia").val("");
                // $(".organizacion_distrito").removeAttr("required");
                // $(".organizacion_distrito").val("");
                $(".titular_suscriptor_profesion_cargo").attr("required", "required");
                $(".titular_suscriptor_profesion_cargo").show();
            }
        })
        $(".tipo_de_certificado").trigger('change')
    }

    $('.sign_block_working_button').on('click', function () {
        $(".sign_block_working").removeClass("hide");
        $(".sign_block_working_button").addClass("hide");
    });

    $('[data-open="ajax-reveal"]').on('click', function (e) {
        var filename = $(this).data('filename')
        var url = $(this).data('url')
        console.log(url)
        $('.ajax-reveal-title').text(filename);
        $('object.ajax-reveal-content').attr('data', url);
        $('object.ajax-reveal-content').load(url);
    });

    PrevenirClickArriba();

    function PrevenirClickArriba() {
        //Evitar que al hacer click en un href="#" regrese arriba
        $('a[href="#"]').click(function (e) {
            e.preventDefault();
        });
    };

    $('.banner_slider').slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        dots: true,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 1
            }
        }]
    });

    $(document).ready(function () {
        $('.select2').select2();
    });

    $("h1.big").fitText(1.2);
    $("h2.big").fitText(1.5);

    $(function () {
        $(document).ready(function () {
            $(".filter").on("change", function () {
                var filter = $(this).val();
                var type = $(this).attr('type');
                if (filter.length > 0) {
                    window.location = '?' + type + '=' + filter;
                } else {
                    window.location = '?'
                }
            });
        });
    });
    Cocoon();
    LoadClipboardJS();
    LoadFilepond();

    $(document).foundation()
});

window.ChangeNotaPendiente = function () {
    $("#note_pendiente").off("click").on("click", function () {
        var _this = $(this);
        if (_this.prop("checked")) {
            $("#note_para_el_cliente").prop("checked", false);
        }
    })
    $("#note_para_el_cliente").off("click").on("click", function () {
        var _this = $(this);
        if (_this.prop("checked")) {
            $("#note_pendiente").prop("checked", false);
        }
    })
}

window.CargarEventosRecaptchaJson = function () {
    $("#submit-btn").unbind().on('click', function (e) {
        grecaptcha.execute();
    });

}

window.submitInvisibleRecaptchaForm = function () {
    $("#submit-btn").trigger("click")
};

window.Cocoon = function () {
    $(".nested-form a.add_fields").data("association-insertion-position", 'before').data("association-insertion-node", 'this');
    $("form").on('cocoon:after-insert', function () {
        $('.select2').select2();
    });
};

window.LoadClipboardJS = function () {
    $('.copiar_clave_publica').each(function () {
        var $button = $(this);
        var clip = new ClipboardJS(this);
        clip.on('success', function () {
            $button.val('Copiado!');
            $button.addClass('success');
            window.setTimeout(function () {
                $button.val('Copiar');
                $button.removeClass('success');
            }, 3000);
        });
    });
}
window.LoadFilepond = function () {
// Only load Culqi script
    if (document.querySelectorAll("script[src='https://unpkg.com/filepond/dist/filepond.min.js']").length === 0) {
        return
    }

    var auth_token = $('meta[name="csrf-token"]').attr('content');
    var procesar_pdf_button = $(".procesar_pdf_button");
    var procesar_pdf_button_captcha = $(".procesar_pdf_button_captcha");
    var procesar_type = procesar_pdf_button.attr("type")
    var procesar_pdf_response = $('#procesar_pdf_response');
    var loading_check = $(".loading_check");

    // First register any plugins
    $.fn.filepond.registerPlugin(FilePondPluginFileValidateSize);
    $.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
    $.fn.filepond.registerPlugin(FilePondPluginPdfPreview);
    $.fn.filepond.setOptions({
        labelIdle: 'Arrastrar y soltar archivo aquí o <span class="filepond--label-action"> elegir</span>',
        labelFileLoading: 'Cargando...',
        labelFileProcessing: 'Procesando...',
        labelFileProcessingComplete: 'Archivo subido',
        labelTapToCancel: 'Cancelar',
        labelTapToRetry: 'Tocar para cancelar',
        labelTapToUndo: 'Deshacer',
        server: {
            url: '/check-files',
            timeout: 7000,
            process: {
                url: '/process',
                method: 'POST',
                headers: {'X-CSRF-Token': auth_token},
                withCredentials: false,
                onload: (response) => {
                    var response_json = $.parseJSON(response)

                    if (response_json.success === true) {

                        if (procesar_type === "validar") {
                            procesar_pdf_button.text("VALIDAR PDF")
                            procesar_pdf_button_captcha.text("VALIDAR PDF")
                            var url = "/check-files/" + response_json.id + "/validate"

                        } else if (procesar_type === "sellar") {
                            procesar_pdf_button.text("SELLAR PDF")
                            procesar_pdf_button_captcha.text("SELLAR PDF")
                            var url = "/check-files/" + response_json.id + "/stamp"
                        }

                        procesar_pdf_button.attr("url", url)
                    } else {
                        alert(response_json.error)
                    }
                },
                onerror: (response) => response.data,
                ondata: (formData) => {
                    formData.append('type', procesar_type);
                    return formData;
                },
            },
            revert: '/revert',
            restore: null,
            load: null,
            fetch: null,
        },
        allowPdfPreview: true,
        pdfPreviewHeight: 320,
        pdfComponentExtraParams: 'toolbar=0&view=fit&page=1',
        allowMultiple: false,
        allowFileSizeValidation: true,
        maxFileSize: "50MB",
        maxTotalFileSize: "50MB",
        labelMaxFileSizeExceeded: "Arhivo es demasiado grande, permite hasta 50MB",
        labelMaxTotalFileSizeExceeded: "Arhivos son demasiados grandes, permite hasta 50MB",
        labelMaxTotalFileSize: "El tamaño que se permite es {filesize}",
        allowFileTypeValidation: true,
        acceptedFileTypes: ["application/pdf"],
        fileValidateTypeLabelExpectedTypesMap: {'application/pdf': '.pdf'},
        labelFileTypeNotAllowed: 'Tipo de archivo incorrecto, acepta PDF',
    });

    // Turn input element into a pond
    $('.procesar-pdf').filepond();

    // Listen for addfile event
    $('.procesar-pdf').on('FilePond:addfile', function (e) {
        // console.log('file added event', e);
    });

    // Listen for addfile event
    $('.procesar-pdf').on('FilePond:processfiles', function (e) {
        procesar_pdf_button_captcha.removeClass("hide");
    });

    // Listen for addfile event
    $('.procesar-pdf').on('FilePond:removefile', function (e) {
        procesar_pdf_button_captcha.addClass("hide");
        procesar_pdf_response.empty();
    });

    $('.procesar_pdf_button').click(function () {

        var response = grecaptcha.getResponse();
        if (response.length == 0) {
            alert("Error, por favor intente de nuevo")
        } else {
            var url = $(this).attr("url");

            loading_check.removeClass("hide");

            $.ajax({
                url: url,
                type: "POST",
                data: {
                    authenticity_token: auth_token,
                },
                success: function (response) {
                    loading_check.addClass("hide");
                    procesar_pdf_button_captcha.addClass("hide");
                    procesar_pdf_response.empty();

                    if (procesar_type === "validar") {
                        procesar_pdf_response.append(response);
                        procesar_pdf_response.foundation();
                        LoadClipboardJS();

                    } else if (procesar_type === "sellar") {
                        if (response.success === true) {
                            var response = '<a class="button large success" href="' + response.public_url + '" target="_blank">DESCARGAR PDF SELLADO <i class="fa fa-check"></i></a>'
                        } else {
                            var response = response.error
                        }
                        response = '<p class="red text-center lead">' + response + '</p>'
                        procesar_pdf_response.append(response);
                    }

                },
                error: function (e) {
                    console.log(JSON.stringify(e));
                }

            })
        }


    })
}